.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

#start-meet {
  cursor: pointer;
  background-color: #1a73e8;
  color: white;
  padding: 15px 30px;
  font-weight: 600;
  border: none;
  font-size: 18px;
  border-radius: 8px;
}
/* change #1a73e8 to disabled blueish color for disabled button*/
#start-meet:disabled {
  background-color: #6786af;
  color: #fff;
  border-color: #1a73e8;
  cursor: not-allowed;
}


/* loader */
.dotscontainerLoad{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dotLoad {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #0e77d3;
  animation: pulse 1.5s infinite ease-in-out;
}

.dotLoad:last-child {
  margin-right: 0;
}

.dotLoad:nth-child(1) {
  animation-delay: -0.3s;
}

.dotLoad:nth-child(2) {
  animation-delay: -0.1s;
}

.dotLoad:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #0e77d3;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}
/* loader */