.popupWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1200;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    z-index: 3555;

    .popupContainer {
        width: 500px;
        margin: auto;
        background: #fff;
        border: 1px solid #EEEEEE;
        border-radius: 15px;
        overflow: hidden;
    }

    .popupHeader {
        border-bottom: none;
        justify-content: flex-end;
        padding: 15px 20px 0;
        display: flex;
        align-items: center;    
    }

    .close {
        display: flex;
        align-items: center;
        padding: 2px;
        border: 1px solid var(--grey2);
        border-radius: 5px;
        cursor: pointer;
    }

    .popupBody {
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        min-height: max-content;
        padding: 0 20px;
        padding-bottom: 40px;
        max-height: calc(100vh - 160px);
        overflow: auto;
        display: flex;
        position: relative;
        align-items: center;
    }

    .logOutText {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        max-width: 200px;
        display: block;
        text-align: center;
    }

    .buttonWrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        width: 100%;
    }

    .button {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        padding: 6px 8px;
        margin: 0;
        border: 1px solid transparent;
        background: transparent;
        color: var(--black3);
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        transition: 0.4s ease-in;
    }

    .RedFillButton {
        border: 1px solid #E53935;
        background: #E53935;
        color: #fff;
    }

    .GreyFillButton {
        border: 1px solid #E5E5E5;
        background: #E5E5E5;
        color: #121212;
    }

    .LogoutPopup .buttonWrapper button {
        padding: 10px 20px;
        font-size: 14px;
    }
    
}